import React from 'react';
import CardItem from './CardItem';
import './Services.css';
import "@fontsource/poppins";

function Services() {
  return (
    <div className='services'>
        <h1 id="services">
            Fixo Services
        </h1>
        <div className='welcome__msg'>
            <h4 id="welcome">How can we help you today?</h4>
        </div>
        <div className="cards__container">
            <div className="cards__wrapper">
              <ul className='cards__items'>
                <CardItem
                  src='images/fixo_it.png'
                  // text='Fixo it now!'
                  // label='Choa Chu Kang | HDB Resale | 2023'
                  path='/fixoit'
                />
                <CardItem
                  src='images/smart_home1.png'
                  // text='Smart Home Plans'
                  // label='Ang Mo Kio | HDB Resale | 2024'
                  path='/smarthome'
                />
                <CardItem
                  src='images/renovation.png'
                  // text='Full Renovation Services'
                  // label='Jervois Road | Apartment | 2022'
                  path='/renovation'
                />
              </ul>
            </div>
        </div>
    </div>
  )
}

export default Services