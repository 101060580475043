import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          <h2 id="home">Fixo your issues today!</h2>
          <br/>
          <p>
            Let our experts and specialists handle it, make an appointment with us.
            <br/>
            <br/>
            <HashLink smooth to='/renovation'>
              <Button buttonStyle='btn--outline' buttonSize='btn--large'>
                <p>Find Us</p>
              </Button>
            </HashLink>
          </p>
        </p>
        {/* <p className='footer-subscription-text'>
          Don't wait, feel free to find us anytime
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='name'
              type='name'
              placeholder='Your Name'
            />
            <input
            className='footer-input'
            name='number'
            type='tel'
            placeholder='Your Phone Number'
            />
            <input
            className='footer-input'
            name='email'
            type='email'
            placeholder='Your Email'
            />
            <input
            className='footer-input'
            name='property-type'
            type='text'
            placeholder='Property Type'
            />
            <Button buttonStyle='btn--outline'>Submit</Button>
          </form>
        </div> */}
      </section>
      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/careers'>Careers</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.instagram.com/lyconsultancygroup?igsh=M3I0ZXdrN2RoaHZl'>Instagram</Link>
            <Link to='https://www.facebook.com/profile.php?id=61557754317103'>Facebook</Link>
            <Link to='https://www.tiktok.com/@lyconsultancy'>TikTok</Link>
          </div>
        </div>
      </div> */}
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='https://www.facebook.com'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://www.instagram.com'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link tiktok'
              to='https://www.tiktok.com'
              target='_blank'
              aria-label='Tiktok'
            >
              <i className='fab fa-tiktok' />
            </Link>
          </div>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              {/* LY <i class='fab fa-typo3' /> */}
              <img className='ly-logo' src='images/FIXO_LOGO.png' alt="FIXO" class='rounded-circle' width='100px' />
            </Link>
          </div>
          <h2>
            <p style={{color: "#fff", margin: "6px 0"}}>
              <b>By LY Group</b> <br />
              <b>Innovate. Integrate. Elevate.</b>
            </p>
          </h2>
          <small className='website-rights'>LY © 2024</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
