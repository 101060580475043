import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css';
import { HashLink } from 'react-router-hash-link';
import "@fontsource/poppins";
import { animated, useSpring } from '@react-spring/web';

function Navbar() {
  const [click, setClick] = useState(false);
  const [socials, setSocials] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  
  const handleClick = () => {
    setClick(!click);
    setScrolling(!click);
  }
  const closeMobileMenu = () => setClick(false);

  const showSocials = () => {
    const match = `(max-width: 960px)`;
    // if (window.innerWidth <= 960) {
    if (matchMedia(match).matches) {
        setSocials(true)
    } else {
        setSocials(false);
    }
  }

  const handleScroll = (event) => {
    // if ((window.scrollY <= 0) && (click === false)) {
    // if ((window.scrollY > 0) || (click === true)) {
    if ((window.scrollY > 0)) {
        setScrolling(true);
    }
    else {
        setScrolling(false);
    }
  };

  const { background, marginTop, height, ...springProps } = useSpring({
    background: scrolling ? "rgba(28, 28, 28, 0.85)" : "transparent",
    marginTop: scrolling ? "-90px" : "-110px",
    height: scrolling ? "90px" : "110px",
    from: {
        background: "transparent",
        marginTop: "-110px",
        height: "110px",
    }
  });


  useEffect(() => {
    showSocials(window.matchMedia('(max-width: 960px)').matches);

    window.addEventListener('resize', showSocials);
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('resize', showSocials);
        window.removeEventListener('scroll', handleScroll);
  }
  }, []);

  return (
    <>
        <animated.nav style={{background: background, marginTop: marginTop, ...springProps }} className="navbar">
            <animated.div style={{height: height, ...springProps }} className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    {/* LY <i className='fab fa-typo3' /> */}
                    <img className='ly-logo' src='images/fixo_logomark.png' alt="LY" height='70px' />
                    <img className='ly-logo' src='images/Fixo_Light_Text.png' alt="LY" height='52px' style={{ margin: "3px 0 0 0" }} />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fa fas fa-times' : 'fa fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <HashLink smooth to='/smarthome' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Poppins"}}>Smart Home Assistance</p>
                        </HashLink>
                    </li>
                    <li className='nav-item'>
                        <HashLink smooth to='/renovation' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Poppins"}}>Full Renovation Services</p>
                        </HashLink>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/careers' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Poppins"}}>LY GROUP</p>
                        </Link>
                    </li> */}
                    <li className='nav-item'>
                        <HashLink smooth to='/fixoit' className='nav-links-mobile' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Poppins"}}>Fixo it!</p>
                        </HashLink>
                    </li>
                    {socials &&
                        <>
                            <div className='nav-social-icons'>
                                <Link
                                className='social-icon-link facebook'
                                to='https://www.facebook.com'
                                target='_blank'
                                aria-label='Facebook'
                                >
                                <i className='fab fa-facebook-f' />
                                </Link>
                                <Link
                                className='social-icon-link instagram'
                                to='https://www.instagram.com'
                                target='_blank'
                                aria-label='Instagram'
                                >
                                <i className='fab fa-instagram' />
                                </Link>
                                <Link
                                className='social-icon-link tiktok'
                                to='https://www.tiktok.com'
                                target= '_blank'
                                aria-label='Tiktok'
                                >
                                <i className='fab fa-tiktok' />
                                </Link>
                            </div>
                            <div className='nav-footer-logo' style={{ padding: "10px 0" }}>
                                <Link to='/' className='social-logo'>
                                    <img className='ly-logo' src='images/fixo_logomark.png' alt="Fixo" class='rounded-circle' width='55px' />
                                </Link>
                            </div>
                            <small className='website-rights'>LY © 2024</small>
                        </>
                    }
                </ul>
            </animated.div>
        </animated.nav>
    </>
  )
}

export default Navbar