import React from 'react'
import '../App.css'
import { HashLink } from 'react-router-hash-link';

function LYGroup() {
  return (
    <>
      <div className='careers'>
        <div className='careers-title'>
          {/* <h5 className='company-header'>- INTERIOR DESIGN FIRM BASED IN SINGAPORE - <br /></h5> */}
          <h5 className='company-title'>Fixo Smart Home Plans</h5>
        </div>
        <div className='careers-content'>
          <div className='careers-text'>
            <div className='careers-journey'>
              <h4><strong>Let us Worry for you.</strong></h4>
              <div class='text-divider'></div>
              <p><strong>Aircon Maintenance Plan</strong></p>
              <p>- Every 3 months we will send a dedicated technician to maintain your aircon, including deep cleaning etc.</p>
              <p><strong>Security Enhancement</strong></p>
              <p>
                - CCTV Planning and Installment. <br/>
                - Anti Blindspots Lookout.</p>
              <p><strong>Swimming Pool Maintenance</strong></p>
              <p>- Recurring maintenance and cleaning based on your pool.</p>
            </div>
            <div className='careers-services'>
              <h4>{"\u00a0"}</h4>
              <div class='text-divider'></div>
              <p><strong>Housekeeping Plans</strong></p>
              <p>
                - Weekly<br/>
                - Bi-Weekly<br/>
                - Monthly<br/>
              </p>
              <p><strong>Pest Control Plans</strong></p>
              <p>
                - Annual Pest Control Checks and Maintenance
                - One time Deep Pest Extermination Service
              </p>
              <p><strong>Mini Facilities Manager</strong></p>
              <p>- Care for your house/facility 24/7.</p>
            </div>
          </div>
          <div className="back-link">
            <HashLink smooth to='/#portfolio' style={{color: "#000"}}>
                <p style={{fontSize: "36px", margin: "-18px 0px 0px", padding: "0px 0px 50px"}}>
                <br/>
                ← Back to Home
                </p>
            </HashLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default LYGroup