"use client";

import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import '../../App.css'
import '../../FixoIt.css'
import { Button } from '../Button';
import Footer from '../Footer';
// import {
//   APIProvider,
//   Map,
//   AdvancedMarker,
//   Pin,
//   InfoWindow,
// } from "@vis.gl/react-google-maps";
import { useNavigate } from 'react-router-dom';


function FixoIt() {
  const position = { lat: 1.29132, lng: 103.85772 };
  const center_position = { lat: 1.301, lng: 103.85772};
  const [open, setOpen] = useState(true);
  // const GMAPS_API_KEY = `${process.env.REACT_APP_GMAPS_API_KEY}`;
  // const GMAPS_MAP_ID = `${process.env.REACT_APP_GMAPS_MAP_ID}`;

  let navigate = useNavigate();
  const submitHandler = (e) =>{
    e.preventDefault();
    let myForm = document.getElementById("enquiry");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate('/thank-you'))
      .catch((error) => alert(error));
  }

  return (
    <>
      <div id="findus" className='find-us'>
        {/* <h1>Journey with us<br /></h1> */}
        {/* <APIProvider apiKey={process.env.REACT_APP_GMAPS_API_KEY}>
          <div className="gmap-style">
            <Map mapId={process.env.REACT_APP_GMAPS_MAP_ID} defaultZoom={14} defaultCenter={center_position} gestureHandling={"cooperative"}>
              <AdvancedMarker position={position} onClick={() => setOpen(true)}>
                <Pin
                  background={"peru"}
                  borderColor={"white"}
                  glyphColor={"lawngreen"}
                />
              </AdvancedMarker>

              {open && (
                <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
                  <div className='address'>
                    <h4 style={{textAlign: "left"}}>Address: </h4>
                    <p style={{textAlign: "left"}}>6 Raffles Blvd, #03-308 Marina Square</p>
                    <p style={{textAlign: "left"}}>Singapore 039594</p>
                    <p><br/></p>
                  </div>
                </InfoWindow>
              )}
            </Map>
          </div>
        </APIProvider> */}
        <div className='enquiry-form'>
          {/* <h4>Operating hours: </h4> */}
          <h1 className='enquiry-form-title'>FIXO IT HERE!</h1>
          <p style={{textAlign: "center"}}><b>Take a photo, describe what you wish for! </b></p>
          <p>Sit back, relax and let out experts handle it.</p>
          <div className='form-input'>
            <form name='enquiry' id='enquiry' action="/thank-you" method='post' data-netlify="true" onSubmit={submitHandler}>
              <input type='hidden' name='form-name' value='enquiry' />
              <input
                className='findus-input'
                type='text' required
                id='name'
                name='name'
                placeholder='*Your Name'
              />
              <input
                className='findus-input'
                type='text' required
                id='email'
                name='contact'
                placeholder='*Your Contact No./Email'
              />
              <input
                className='findus-input'
                type='text' required
                id='address'
                name='address'
                placeholder='*Your Address/Area'
              />
              <textarea
                className='pic-attach'
                id='picattach'
                name='picattach'
                placeholder='Picture of What you Wish For'
                wrap='soft'
              />
              <textarea
                className='findus-message'
                id='message'
                name='message'
                placeholder='Your Renovation Details'
                wrap='soft'
              />
              <Button
                className='btn-input' 
                buttonStyle='btn--outline' 
                buttonSize='btn--large'
                type='submit'
              >Submit</Button>
            </form>
          </div>
          <p className="contact-title">
              Or <strong>Contact Us at: </strong><br/>
          </p>
          <p className="contact-details">
            <strong>Phone No.:</strong> +65 98295375 <br/>
            <strong>Email:</strong> fixo@lyconsultancygroup.com
          </p>
        </div>
        {/* <div className='operating-hours'>
          <h4 className="gm-iw-email">
            Contact: +65 98295375 <br/>
            Email: fixo@lyconsultancygroup.com
          </h4>
          <p><br/></p>
        </div> */}
        <HashLink smooth to='/#portfolio' style={{color: "#fff"}}>
            <p style={{fontSize: "36px", margin: "-18px 0px 0px", padding: "0px 0px 50px"}}>
              ← Back to Home
            </p>
        </HashLink>
      </div>
      <Footer />
    </>
  )
}

export default FixoIt