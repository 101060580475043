import React from 'react';
import '../App.css';
// import { Button } from './Button';
import './Landing.css';
import { Link } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';

import "@fontsource/quicksand";
import "@fontsource/poppins";

function Landing(props) {

  return (
    <div id='start' className='landing'>
      {/* <div dangerouslySetInnerHTML={{ __html: `
        <video
          autoplay
          loop
          muted
          playsinline
          poster="/images/tanjong_rhu_master_bed.jpg"
          src="videos/bg-vid.mp4"
          ref="{playerRef}"
          class="${props.className}">
        </video>
        ` }}></div> */}
      <div className='landing-content'>
        <h1>
          HOME REPAIR & <br/>
          RENOVATION <br/>
          SERVICES AT YOUR <br/>
          FINGERTIPS  
        </h1>
        <p className='landing-subtext'>
          <b>Meet Fixo</b> - A first of its kind application designed <br/> 
          for the needs of every modern homeowner.
        </p>
        <div className="landing-btns">
          <Link
            to='https://www.apple.com/app-store/'
            className="app-store-btn"
            target='_blank'
            aria-label='Apple App Store'
            style={{ cursor: "pointer" }}
          >
            <img src="images/App_Store.svg" alt="Apple App Store" height='45px' />
          </Link>
          <Link
            to='https://play.google.com/store/apps'
            className="google-play-btn"
            target='_blank'
            aria-label='Google Play Store'
            style={{ cursor: "pointer" }}
          >
            <img src="images/Google_Play.svg" alt="Google Play Store" height='45px' />
          </Link>
          <Link
            to='https://appgallery.huawei.com/'
            className="huawei-btn"
            target='_blank'
            aria-label='Huawei App Gallery'
            style={{ cursor: "pointer" }}
          >
            <img src="images/Huawei_App_Gallery.svg" alt="Huawei App Gallery" height='46px' />
          </Link>
          <image className="buffer-img" />
          <Link
            to='https://www.apple.com/app-store/'
            className="get-started-btn"
            target='_blank'
            aria-label='Get Started'
            style={{ cursor: "pointer" }}
          >
            <img src="images/get_started.svg" alt="Get Started" height='51px' style={{ marginTop: "-7px" }} />
          </Link>
        </div>
        <div className="howto" dangerouslySetInnerHTML={{ __html: `
          <video
            autoplay
            loop
            muted
            playsinline
            poster="/images/How to use FIXO.png"
            src="videos/How to use FIXO.mp4"
            ref="{playerRef}"
            class="${props.className}">
          </video>
        ` }}></div>
        <div className="divider">
          {/* <h1>· · ─────── ·𖥸· ─────── · ·</h1> */}
          <h3>· · ───────── ·𖥸· ───────── · ·</h3>
        </div>
      </div>
    </div>
  )
}

export default Landing;