import React from 'react';
import '../../App.css';
import Landing from '../Landing';
import Services from '../Services';
import Footer from '../Footer';

function Home() {
  return (
    <>
        <Landing />
        <Services />
        <Footer />
    </>
  );
}

export default Home;
